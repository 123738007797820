// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Your custom styles

const Footer = () => {
  return (
    <footer className="bg-light text-dark text-center p-3">
      <p>&copy; 2024 Occasio.events</p>
    </footer>
  );
};

export default Footer;
