// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import './css/AdminDashboard.css';

const AdminDashboard = () => {
  const [eventRequests, setEventRequests] = useState([]);

  useEffect(() => {
    const fetchEventRequests = async () => {
      const db = getFirestore();
      const eventRequestsSnapshot = await getDocs(collection(db, 'eventRequests'));
      const requests = eventRequestsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setEventRequests(requests);
    };

    fetchEventRequests();
  }, []);

  const handleApprove = async (id) => {
    const db = getFirestore();
    const eventRequestDoc = doc(db, 'eventRequests', id);
    await updateDoc(eventRequestDoc, { status: 'Approved' });
    setEventRequests(eventRequests.map(request => request.id === id ? { ...request, status: 'Approved' } : request));
  };

  const handleDeny = async (id) => {
    const db = getFirestore();
    const eventRequestDoc = doc(db, 'eventRequests', id);
    await updateDoc(eventRequestDoc, { status: 'Denied' });
    setEventRequests(eventRequests.map(request => request.id === id ? { ...request, status: 'Denied' } : request));
  };

  return (
    <div className="admin-dashboard-container">
      <h1>Admin Dashboard</h1>
      <div className="event-requests">
        {eventRequests.map(request => (
          <div key={request.id} className="event-request-card">
            <h2>{request.eventName}</h2>
            <p><strong>Company:</strong> {request.companyName}</p>
            <p><strong>Date:</strong> {request.eventDate}</p>
            <p><strong>Description:</strong> {request.eventDescription}</p>
            <p><strong>Status:</strong> {request.status}</p>
            {request.status === 'Pending' && (
              <div className="action-buttons">
                <button onClick={() => handleApprove(request.id)} className="btn btn-success">Approve</button>
                <button onClick={() => handleDeny(request.id)} className="btn btn-danger">Deny</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
