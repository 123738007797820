// src/pages/HostEvent.js
import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './css/HostEvent.css';

const HostEvent = () => {
  const [companyName, setCompanyName] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (user) {
      try {
        // Update user's account type
        await setDoc(doc(db, 'users', user.uid), {
          accountType: 'Event Host',
          companyName,
          eventName,
          eventDate,
          eventDescription,
          email: user.email,
        });

        // Add event request to admin collection
        await setDoc(doc(db, 'eventRequests', user.uid), {
          userId: user.uid,
          companyName,
          eventName,
          eventDate,
          eventDescription,
          email: user.email,
          status: 'Pending'
        });

        navigate('/dashboard'); // Redirect to dashboard page
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <div className="host-event-container">
      <div className="card host-event-card">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Host an Event</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Enter your company name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventName">Event Name</label>
              <input
                type="text"
                className="form-control"
                id="eventName"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                placeholder="Enter your event name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventDate">Event Date</label>
              <input
                type="date"
                className="form-control"
                id="eventDate"
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventDescription">Event Description</label>
              <textarea
                className="form-control"
                id="eventDescription"
                value={eventDescription}
                onChange={(e) => setEventDescription(e.target.value)}
                placeholder="Enter your event description"
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary btn-block">Submit</button>
          </form>
          {error && <p className="text-danger text-center mt-3">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default HostEvent;
