// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './css/Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await user.reload();
      if (!user.emailVerified) {
        alert('Please verify your email before proceeding.');
        navigate('/verify-email');
        return;
      }

      const userDocRef = doc(firestore, 'users', user.uid);
      console.log('Fetching user document:', userDocRef);

      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists() || !userDoc.data().firstName || !userDoc.data().lastName || !userDoc.data().birthday || !userDoc.data().phoneNumber) {
        navigate('/additional-info');
        return;
      }

      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      await user.reload();
      if (!user.emailVerified) {
        alert('Please verify your email before proceeding.');
        navigate('/verify-email');
        return;
      }

      const userDocRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists() || !userDoc.data().firstName || !userDoc.data().lastName || !userDoc.data().birthday || !userDoc.data().phoneNumber) {
        navigate('/additional-info');
        return;
      }

      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleForgotPassword = () => {
    navigate('/reset-password');
  };

  return (
    <div className="login-container">
      <div className="card login-card">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Login</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Login</button>
          </form>
          <button onClick={handleGoogleLogin} className="btn btn-danger btn-block mt-3">Login with Google</button>
          <button onClick={handleSignUp} className="btn btn-secondary btn-block mt-3">Sign Up</button>
          <button onClick={handleForgotPassword} className="btn btn-link btn-block mt-3">Forgot Password?</button>
          {error && <p className="text-danger text-center mt-3">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
