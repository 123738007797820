// src/pages/Dashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleHostEvent = () => {
    navigate('/host-event');
  };

  return (
    <div className="dashboard-container">
      <h1>Welcome to your Dashboard</h1>
      <button onClick={handleHostEvent} className="btn button mt-4">Host an Event</button>
    </div>
  );
};

export default Dashboard;
