// src/mockData.js

const mockEvents = [
  {
    id: '1',
    name: 'Event One',
    image: 'https://via.placeholder.com/150',
    date: '2024-07-27',
    description: 'Description for event one.'
  },
  {
    id: '2',
    name: 'Event Two',
    image: 'https://via.placeholder.com/150',
    date: '2024-08-15',
    description: 'Description for event two.'
  },
  // Add more mock events as needed
];

export default mockEvents;
