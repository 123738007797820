// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDNl6u37VJFSox0-wfzhMPBmtPV-MTcu8I",
    authDomain: "occasioevents-39ee6.firebaseapp.com",
    projectId: "occasioevents-39ee6",
    storageBucket: "occasioevents-39ee6.appspot.com",
    messagingSenderId: "543355500761",
    appId: "1:543355500761:web:20cfb5456c0ff0ad4526c8",
    measurementId: "G-0051KG8882"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log('Firebase initialized:', app);

export const auth = getAuth(app);
export const firestore = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(firestore).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.error('Failed to enable persistence. Multiple tabs open.');
  } else if (err.code === 'unimplemented') {
    console.error('Persistence is not available.');
  }
});
