// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import EventDetails from './pages/EventDetails';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import HostEvent from './pages/HostEvent'; // Import the HostEvent component
import AdminDashboard from './pages/AdminDashboard'; // Import the AdminDashboard component
import ResetPassword from './pages/ResetPassword'; // Import the ResetPassword component

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/event/:id" element={<EventDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/host-event" element={<HostEvent />} /> {/* Add the route for HostEvent */}
        <Route path="/admin-dashboard" element={<AdminDashboard />} /> {/* Add the route for AdminDashboard */}
        <Route path="/reset-password" element={<ResetPassword />} /> {/* Add the route for ResetPassword */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
