// src/pages/EventDetails.js
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import mockEvents from '../mockData';

function EventDetails() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    // Find the event in the mock data
    const foundEvent = mockEvents.find((event) => event.id === id);
    setEvent(foundEvent);
  }, [id]);

  if (!event) return <div>Loading...</div>;

  return (
    <div>
      <h1>{event.name}</h1>
      <img src={event.image} alt={event.name} />
      <p>{event.date}</p>
      <p>{event.description}</p>
    </div>
  );
}

export default EventDetails;
