// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './css/Home.css';

const Home = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const db = getFirestore();
      const eventsCollection = collection(db, 'events');
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map(doc => doc.data());

      console.log('Fetched events:', eventsList); // Debugging statement

      if (Array.isArray(eventsList)) {
        setEvents(eventsList);
      } else {
        console.error('Expected an array of events, but got:', eventsList);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="home-container">
      <h1>Upcoming Events</h1>
      <div className="events-list">
        {events.length > 0 ? (
          events.map((event, index) => (
            <div key={index} className="event-card">
              <h2>{event.name}</h2>
              <p>{event.date}</p>
              <p>{event.description}</p>
            </div>
          ))
        ) : (
          <p>No upcoming events found.</p>
        )}
      </div>
    </div>
  );
};

export default Home;
